<template>
  <div>
    <v-dialog v-model="dialogAddHub" persistent width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="warning" depressed v-bind="attrs" v-on="on">
          <v-icon left> mdi-plus </v-icon>
          Add Hub
        </v-btn>
      </template>

      <v-card>
        <v-card-title> Add Hub </v-card-title>

        <br />

        <v-card-text>
          <v-form class="Form" ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Nama Hub"
                    v-model="hubName"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    :rules="requiredRule"
                    outlined
                    dense
                    label="No. Handphone"
                    v-model="phone"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Email"
                    :rules="requiredRule"
                    v-model="email"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Description"
                    v-model="description"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-combobox
                    outlined
                    dense
                    label="Kota"
                    :rules="requiredRule"
                    :items="cityList"
                    :value="city"
                    @input="setCity"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-combobox
                    outlined
                    dense
                    label="Kelurahan, Kecamatan"
                    :rules="requiredRule"
                    :items="villageList"
                    :value="village"
                    @input="setVillage"
                  ></v-combobox>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Kode Pos"
                    disabled
                    v-model="postCode"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-textarea
                    outlined
                    dense
                    label="Alamat"
                    rows="2"
                    no-resize
                    :rules="rule.alamat"
                    v-model="address"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="dialogAddHub = false">
            cancel
          </v-btn>
          <v-btn color="warning" depressed @click="addHub" :loading="isLoading">
            add hub
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import cityData from "../../../data/city.json";
import villageData from "../../../data/kelurahan.json";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";

export default {
  name: "DialogAddHubComponent",
  data() {
    return {
      hubName: "",
      phone: "",
      email: "",
      description: "",
      city: "",
      idCity: "",
      district: "",
      idDistrict: "",
      village: "",
      kecamatan: "",
      kelurahan: "",
      idVillage: "",
      postCode: "",
      address: "",
      dialogAddHub: false,
      snackbar: false,
      snackbarText: "",
      valid: false,
      requiredRule: [(value) => !!value || "Required"],
      rule: {
        alamat: [
          (value) => !!value || "Required",
          (v) => (v || "").length <= 300 || "Maxsimal input 300 characters",
        ],
      },
      isLoading: false,
    };
  },
  methods: {
    addHub() {
      this.valid = this.$refs.form.validate();
      this.isLoading = true;
      if (this.valid) {
        axios({
          method: "post",
          url: `${baseUrl}/Hub`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
            idho: getProfileCookie().KCX_HOIDHO,
          },
          data: {
            NamaHub: this.hubName,
            Email: this.email,
            Telp: this.phone,
            Alamat: this.address,
            Keterangan: this.description,
            CityIDCity: this.idCity,
            Kecamatan: this.kecamatan,
            Kelurahan: this.kelurahan,
            KodePost: this.postCode,
            KecamatanIDKecamatan: this.idDistrict,
            KelurahanIDKelurahan: this.idVillage,
          },
        })
          .then((res) => {
            this.dialogAddHub = false;
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
            this.$emit("refresh");
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    setCity(e) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);

      if (city) {
        this.idCity = city.idCity;
        this.city = e;
      }
    },
    setVillage(e) {
      const villageName = e.split(",")[0];
      const districtName = e.split(",")[1];
      const village = villageData.find(
        ({ kelurahan, kecamatan }) =>
          kelurahan == villageName && kecamatan == districtName
      );

      if (village) {
        this.idVillage = village.idKelurahan;
        this.idDistrict = village.idKecamatan;
        this.district = e;
        this.kecamatan = districtName;
        this.kelurahan = villageName;
        this.postCode = village.kodePos;
      }
    },
  },
  computed: {
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
    villageList() {
      let villageArray = [];

      if (this.idCity) {
        villageArray = villageData.filter((el) => {
          return el.idCity == this.idCity;
        });

        const villages = villageArray.map((el) => {
          return `${el.kelurahan},${el.kecamatan}`;
        });

        return villages;
      }

      return [];
    },
  },
};
</script>

<style lang="scss" scoped></style>
