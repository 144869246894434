<template>
  <div>
    <v-dialog v-model="DialogEditKomisi" persistent width="1000px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          depressed
          class="mr-2"
          small
          color="primary"
          @click="resetForm"
        >
          <v-icon color="white"> mdi-pencil </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title> ATUR KOMISI </v-card-title>
        <v-card-subtitle
          >Atur komisi hanya berlaku untuk data di halaman yang sedang
          ditampilkan.</v-card-subtitle
        >

        <v-card-text>
          <v-form class="Form" ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col cols="2">
                  <small>Layanan</small>
                  <p>{{ layanan }}</p>
                </v-col>
                <v-col cols="2">
                  <small>Via</small>
                  <p>{{ via }}</p>
                </v-col>
                <v-col cols="2">
                  <small>Komisi Maks (%) <strong>HO</strong></small>
                  <v-text-field
                    outlined
                    dense
                    v-model="maxKomisiHO"
                    readonly
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <small>Komisi Maks (%) <strong>HUB</strong></small>
                  <v-text-field
                    type="number"
                    outlined
                    dense
                    v-model="komisi"
                    :max="maxKomisi"
                    min="0"
                    :rules="ruleKomisiHub"
                  ></v-text-field>
                </v-col>

                <v-col cols="2">
                  <small>Action</small>
                  <v-btn class="mb-5" color="primary" @click="saveKomisi" block>
                    Simpan
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="DialogEditKomisi = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "DialogEditKomisiComponent",
  props: ["dataKomisi", "index", "setDataKomisi"],
  data() {
    return {
      hoName: "",
      phone: "",
      email: "",
      description: "",
      city: "",
      idCity: "",
      district: "",
      idDistrict: "",
      village: "",
      kecamatan: "",
      kelurahan: "",
      idVillage: "",
      postCode: "",
      address: "",
      DialogEditKomisi: false,
      snackbar: false,
      snackbarText: "",
      valid: false,
      requiredRule: [(value) => !!value || "Required"],
      ruleKomisiHub: [
        (value) => !!value || "Required",
        (value) => value <= this.maxKomisi || `Maksimal ${this.maxKomisi}`,
      ],
      dataHO: [],
      ho: "",
      layanan: "",
      komisi: 0,
      showVia: [],
      via: "",
      addKomisi: true,
      idKomisi: null,
      maxKomisi: 0,
    };
  },
  methods: {
    checkKomisi() {
      this.layanan = this.dataKomisi.Via;
      this.via = this.dataKomisi.TypeVia;
      this.addKomisi = false;
      this.komisi = Number(this.dataKomisi.HubKomisi);
      this.maxKomisiHO = Number(this.dataKomisi.HOKomisi);
      this.maxKomisi =
        Number(this.dataKomisi.HubKomisi) + Number(this.dataKomisi.HOKomisi);

      this.idKomisi = this.dataKomisi.IDKomisi;
    },
    saveKomisi() {
      axios({
        method: "post",
        url: `${baseUrl}/Komisi`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: {
          addKomisi: this.addKomisi,
          IDHo: this.dataKomisi.IDHo,
          IDHub: this.dataKomisi.IDHub,
          HOKomisi: this.maxKomisiHO,
          HUBKomisi: Number(this.komisi),
          IDVia: this.dataKomisi.IDVia,
          idKomisi: this.dataKomisi.IDKomisi,
        },
      })
        .then((res) => {
          this.snackbar = true;
          this.snackbarText = res.data.message.info[0];
          this.setDataKomisi(this.index, this.komisi, this.maxKomisiHO);
          this.DialogEditKomisi = false;
        })
        .catch((err) => {
          console.log(err);
          this.snackbar = true;
          this.snackbarText = err.message;
          this.isLoading = false;
        })
        .finally(() => {
          this.idKomisi = null;
        });
    },
    resetForm() {
      this.checkKomisi();
      //   this.$refs.form.reset();
      // this.dataHO = [];
      // this.ho = "";
      // this.komisi = 0;
      // this.showVia = [];
      // this.city = "";
      // this.dataKomisi = [];
      // this.layanan = [];
    },
  },
  computed: {
    maxKomisiHO: {
      get() {
        // console.log(this.maxKomisi);
        let maxHO = this.maxKomisi - this.komisi;

        return maxHO < 0 ? 0 : maxHO;
      },
      set(value) {
        this.value = value;
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
