<template>
  <div class="FareCheck">
    <div class="FareCheck-Form">
      <v-form ref="form" v-model="valid">
        <v-container class="ma-0">
          <v-row>
            <v-col cols="12" sm="4">
              <v-combobox
                outlined
                dense
                label="Kota asal"
                :rules="rules"
                :items="cityList"
                :value="origin"
                @input="setCity($event, 'origin')"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="4">
              <v-combobox
                outlined
                dense
                label="Kota tujuan"
                :rules="rules"
                :items="cityList"
                :value="destination"
                @input="setCity($event, 'destination')"
              ></v-combobox>
            </v-col>
            <v-col cols="12" sm="4">
              <v-combobox
                outlined
                dense
                label="Kelurahan tujuan"
                :rules="rules"
                :items="villageDestinationList"
                :value="villageDestination"
                @input="setPostCodeDestination"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" sm="2">
              <v-select
                :items="modaOption"
                v-model="moda"
                item-text="text"
                item-value="value"
                label="Moda"
                outlined
                dense
                :rules="rules"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="weight"
                label="Berat, Kg"
                type="number"
                :rules="ruleWeight"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="2">
              <v-text-field
                v-model="length"
                label="Panjang"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="width"
                label="Lebar"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <v-text-field
                v-model="height"
                label="Tinggi"
                type="number"
                outlined
                dense
              ></v-text-field>
            </v-col>

            <v-checkbox
              label="Packing Kayu"
              @change="isPacking"
              v-model="packingKayu"
            ></v-checkbox>

            <v-col xs="2">
              <v-btn
                :loading="isLoading"
                color="orange"
                class="white--text"
                depressed
                block
                @click="check"
                >cek tarif</v-btn
              >
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </div>
    <div class="FareCheck-Result">
      <hr />
      <div v-if="isResult">
        <br />
        <h3>Origin: {{ origin }}</h3>
        <h3>Destination: {{ destination }}</h3>

        <v-item-group mandatory v-if="listPrice.length > 0">
          <v-container>
            <v-row>
              <v-col
                v-for="(n, i) in listPrice"
                :key="i"
                md="12"
                sm="12"
                xs="12"
              >
                <v-item v-slot="{ active }">
                  <v-card
                    :color="active ? 'default' : ''"
                    class="d-flex align-center"
                  >
                    <v-scroll-y-transition>
                      <div
                        class="flex-grow-1 text-center"
                        style="font-size: 14px"
                      >
                        <v-row>
                          <v-col sm="12" md="2" xs="12"
                            >Vendor: {{ i + 1 }} <br />
                            <strong
                              ><i>{{ n.description }}</i></strong
                            >
                          </v-col>
                          <v-col
                            cols="2"
                            sm="12"
                            md="2"
                            xs="12"
                            v-if="n.vendor == 'JNE'"
                            >Harga:
                            <strong>{{ convertToRp(n.price) }}</strong></v-col
                          >
                          <!-- <v-col sm="12" md="2" xs="12" v-else
                            >Harga:
                            <strong>
                              {{
                                convertToRp(
                                  packingKayu ? n.price + biayaPacking : n.price
                                )
                              }}</strong
                            ></v-col
                          > -->
                          <v-col sm="12" md="2" xs="12" v-else
                            >Harga:
                            <strong>
                              {{ convertToRp(n.price + biayaPacking) }}</strong
                            ></v-col
                          >
                          <v-col sm="12" md="3" xs="12"
                            >Estimasi Pengiriman: {{ n.etd }}</v-col
                          >
                          <v-col sm="12" md="2" xs="12"
                            >Kategory : {{ n.category }}</v-col
                          >
                          <v-col sm="12" md="3" xs="12">
                            <v-chip
                              class="ma-2"
                              color="green"
                              text-color="white"
                              small
                              v-if="n.dfod"
                            >
                              Bisa Di Tagih Ditujuan
                            </v-chip>
                            <v-chip
                              class="ma-2"
                              color="red"
                              text-color="white"
                              small
                              v-else
                            >
                              Tidak Bisa Di Tagih Ditujuan
                            </v-chip>
                          </v-col>
                        </v-row>
                      </div>
                    </v-scroll-y-transition>
                  </v-card>
                </v-item>
              </v-col>
            </v-row>
            <!-- <p class="mt-5 font-weight-red" style="color: red">
              * Harga Belum Termasuk PPN
            </p> -->
          </v-container>
          <v-img style="margin: 0 auto" :src="tos_domestic" width="50%"></v-img>
        </v-item-group>
        <div v-else>
          <v-card class="d-flex align-center" height="50">
            <v-scroll-y-transition>
              <div class="flex-grow-1 text-center font-weight-black">
                <v-row>
                  <v-col v-if="isLoading" cols="12">Loading...</v-col>
                  <v-col v-else cols="12">Data Not Found</v-col>
                </v-row>
              </div>
            </v-scroll-y-transition>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import cityData from "../../../data/city.json";
import sentralOrigin from "../../../data/originSentral.json";
import originCode from "../../../data/originCode.json";
import villageList from "../../../data/kelurahan.json";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
import { formatToRupiah } from "../../../utils/formatter";
import {
  PACKING_WOOD_SENTRAL,
  ADDITIONAL_LENGTH,
  ADDITIONAL_WIDTH,
  ADDITIONAL_HEIGHT,
} from "../../../constant/insurance";
import tos_domestic from "../../../assets/images/tos_domestic.jpeg";

export default {
  name: "FareCheck",
  data() {
    return {
      origin: "",
      destination: "",
      idOrigin: "",
      idDestination: "",
      // villageOriginList: [],
      // villageOrigin: "",
      // postCodeOrigin: "",
      villageDestinationList: [],
      villageDestination: "",
      postCodeDestination: "",
      moda: "Darat",
      weight: 5,
      valid: false,
      price: "",
      isLoading: false,
      isResult: false,
      minEtd: 0,
      maxEtd: 0,
      rules: [(value) => !!value || "Required"],
      ruleWeight: [
        (value) => !!value || "Required",
        (value) => value >= 5 || "Minimal 5Kg",
      ],
      modaOption: ["Darat", "Udara", "Laut"],
      length: 0,
      height: 0,
      width: 0,
      villageName: "",
      citySNI: "",
      originCode: "",
      cityName: "",
      listPrice: [],
      packingKayu: false,
      biayaPacking: 0,
      tos_domestic,
      KabupatenName: "",
      originKec: "",
    };
  },
  methods: {
    isPacking() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        this.getPrice();
      }
    },
    check() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        this.getPrice();
      }
    },
    setCity(e, type) {
      const cityName = e.split(",")[0];
      const city = cityData.find(({ city }) => city == cityName);
      //get origin sentral
      const orgCity =
        cityName.search("Jakarta") > 0
          ? "Jakarta"
          : cityName
              .replace("Kabupaten", "")
              .replace("Kota", "")
              .replace("Selatan", "")
              .replace("Timur", "")
              .replace("Utara", "")
              .replace("Barat", "")
              .replace("Tenggara", "")
              .replace("Daya", "")
              .replace("Tengah", "");
      // console.log(orgCity.trim());

      // console.log(orgCity);
      const originSentral = sentralOrigin.find(
        ({ OriginName }) =>
          OriginName.toLowerCase().trim() == orgCity.toLowerCase().trim()
      );
      // console.log(originSentral);
      const orgnJne =
        cityName.search("Jakarta") > 0
          ? "Jakarta"
          : cityName.replace("Kabupaten", "").replace("Kota", "");
      //filter
      const orgnCode = originCode.filter((object) =>
        Object.values(object).some((i) =>
          i.toLowerCase().includes(orgnJne.toLowerCase().trim())
        )
      );

      if (city) {
        if (type == "origin") {
          let codeOrigin = city.OriginCode ? city.OriginCode : "10000";
          this.cityName = originSentral ? originSentral.OriginDestCode : "";
          this.citySNI = city.CitySNI;
          this.origin = e;
          this.idOrigin = city.idCity;
          this.originCode =
            orgnCode.length > 0
              ? orgnCode[0].originCode
              : `${city.CitySNI}${codeOrigin}`;
        } else {
          this.destination = e;
          this.idDestination = city.idCity;
          this.KabupatenName = orgnJne;
        }
      }
    },
    setPostCodeDestination(e) {
      const villageName = e.split(",")[0];
      const districtName = e.split(",")[1];
      const postCode = e.split(",")[2];
      // const village = villageList.find(
      //   ({ kelurahan, kecamatan }) =>
      //     kelurahan == villageName && kecamatan == districtName
      // );

      this.postCodeDestination = postCode.trim().toString();
      this.villageName = villageName.trim().toLowerCase();
      this.originKec = districtName.trim().toLowerCase();
    },
    // setPostCodeOrigin(e) {
    //   const villageName = e.split(",")[0];
    //   const districtName = e.split(",")[1];

    //   const village = villageList.find(
    //     ({ kelurahan, kecamatan }) =>
    //       kelurahan == villageName && kecamatan == districtName
    //   );

    //   this.postCodeOrigin = village.kodePos;
    // },
    getPrice() {
      let height, length, width;
      this.listPrice = [];
      this.isLoading = true;
      if (this.packingKayu == true) {
        height = Number(this.height) + ADDITIONAL_HEIGHT;
        length = Number(this.length) + ADDITIONAL_LENGTH;
        width = Number(this.width) + ADDITIONAL_WIDTH;

        let rp = PACKING_WOOD_SENTRAL;
        this.biayaPacking = ((width + height + length) / 3) * rp;
      } else {
        height = Number(this.height);
        length = Number(this.length);
        width = Number(this.width);
      }
      axios({
        url: `${baseUrl}/CheckPrice`,
        method: "post",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: {
          Length: length,
          Height: height,
          Width: width,
          Weight: Number(this.weight),
          CitySNI: this.citySNI,
          OriginCode: this.originCode,
          // CityIDCity: this.idOrigin,
          // IDCityDestination: this.idDestination,
          VillageName: this.villageName,
          KCX_ViaIDVia: this.moda,
          KCX_ServiceIDService: 9,
          // KodePosOrigin: this.postCodeOrigin.toString(),
          DestKecamatan: this.originKec,
          KabupatenName: this.KabupatenName,
          CityName: this.cityName,
          Moda: this.moda.toLowerCase(),
          IDCityOrigin: this.idOrigin,
          KodePosDestination: this.postCodeDestination.toString(),
          DetailKoli: [
            { length, height, width, weight: this.weight, numberColly: 1 },
          ],
        },
      })
        .then((res) => {
          this.isResult = true;
          this.minEtd = 0;
          this.maxEtd = 0;
          this.price = formatToRupiah(0);
          this.listPrice = res.data.data.price;
          this.listPrice.sort(
            (a, b) => parseFloat(a.price) - parseFloat(b.price)
          );
          this.isLoading = false;
        })
        .catch(() => {
          this.isResult = true;
          this.price = "No data";
          this.isLoading = false;
        });
    },

    convertToRp(bil) {
      return formatToRupiah(bil);
    },
  },
  computed: {
    cityList() {
      const cityArray = cityData.map((el) => {
        return `${el.city}, ${el.propinsi}`;
      });

      return cityArray;
    },
  },
  watch: {
    idDestination: function (val) {
      let villageArray = [];
      if (val) {
        villageArray = villageList.filter((el) => el.idCity == val);
      }

      this.villageDestinationList = villageArray.map((el) => {
        return `${el.kelurahan}, ${el.kecamatan}, ${el.kodePos}`;
      });
    },
    // idOrigin: function (val) {
    //   let villageArray = [];
    //   if (val) {
    //     villageArray = villageList.filter((el) => el.idCity == val);
    //   }

    //   this.villageOriginList = villageArray.map((el) => {
    //     return `${el.kelurahan},${el.kecamatan}`;
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.FareCheck {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
}
</style>
