const env = process.env.NODE_ENV;

let baseUrl = "";
let urlVA = "";
let urlTracking = "";

switch (env) {
  case "development":
    baseUrl = "https://api-dev.trolly-app.com";
    // baseUrl = "http://localhost:2000";
    urlVA = "http://54.151.202.224:5000/v1";
    urlTracking = "https://openapitrolly.indokreatif.com/v1/tracking/";
    break;
  case "production":
    baseUrl = "https://api.trolly-app.com";
    urlVA = "http://13.229.224.96:5000/v1";
    urlTracking = "https://openapi.trolly-app.com/v1/tracking/";
    break;
}

export { baseUrl, urlTracking, urlVA };
