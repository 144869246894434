<template>
  <div>
    <v-dialog v-model="dialogAddNorek" persistent width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="warning" depressed v-bind="attrs" v-on="on">
          <v-icon left> mdi-plus </v-icon>
          Add Norek
        </v-btn>
      </template>

      <v-card>
        <v-card-title> Add Norek </v-card-title>

        <br />

        <v-card-text>
          <v-form class="Form" ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Nama Bank"
                    v-model="namaBank"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    label="Nomor Rekening"
                    v-model="nomorRekening"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    outlined
                    dense
                    type="number"
                    label="Minimal TopUP"
                    v-model="minTopup"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="dialogAddNorek = false">
            cancel
          </v-btn>
          <v-btn color="warning" depressed @click="addNorek"> add Norek </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";

export default {
  name: "DialogAddHubComponent",
  data() {
    return {
      dialogAddNorek: false,
      snackbar: false,
      snackbarText: "",
      valid: false,
      requiredRule: [(value) => !!value || "Required"],
      rule: {
        alamat: [
          (value) => !!value || "Required",
          (v) => (v || "").length <= 300 || "Maxsimal input 300 characters",
        ],
      },
      namaBank: "",
      nomorRekening: "",
      minTopup: 0,
      IDHO: getProfileCookie().KCX_HOIDHO,
    };
  },
  methods: {
    addNorek() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        axios({
          method: "post",
          url: `${baseUrl}/Norek`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            NamaBank: this.namaBank,
            IDHO: this.IDHO,
            NomorRekening: this.nomorRekening,
            MinTopup: this.minTopup,
          },
        })
          .then((res) => {
            this.dialogAddNorek = false;
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
            this.$emit("refresh");
            this.namaBank = "";
            this.nomorRekening = "";
            this.minTopup = "";
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          });
      }
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
