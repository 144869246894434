import axios from "axios";

import { baseUrl } from "../constant/url";
import { getTokenCookie, getProfileCookie } from "../utils/cookies";

export async function getFinancialReport(startDate, endDate) {
  const { data: resp } = await axios({
    method: "get",
    url: `${baseUrl}/financial/report?startDate=${startDate}&endDate=${endDate}`,
    headers: {
      Authorization: `Bearer ${getTokenCookie()}`,
      idho: await getProfileCookie().KCX_HOIDHO,
    },
  });
  return resp.data;
}

export async function getFinancialReportDetail(date) {
  const { data: resp } = await axios({
    method: "get",
    url: `${baseUrl}/financial/report/${date}`,
    headers: {
      Authorization: `Bearer ${getTokenCookie()}`,
    },
  });
  return resp.data;
}
