<template>
  <div class="DropDownManifestComponent">
    <v-data-table :headers="headers" :items="detailColli" hide-default-footer>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn
          depressed
          small
          color="primary"
          @click="sendOne(item)"
          :disabled="item.idStatus >= 12"
        >
          jne
        </v-btn>
      </template>
      <template v-slot:[`item.packing`]="{ item }">
        <v-chip
          v-if="item.IsPacking"
          class="ma-2"
          color="green"
          text-color="white"
          x-small
        >
          Ya
        </v-chip>
        <v-chip v-else class="ma-2" color="red" text-color="white" x-small>
          Tidak
        </v-chip>
      </template>
      <template v-slot:[`item.pPanjang`]="{ item }">
        {{ item.Panjang }}
        <span v-if="item.IsPacking">({{ originalPanjang(item.Panjang) }})</span>
      </template>
      <template v-slot:[`item.pLebar`]="{ item }">
        {{ item.Lebar }}
        <span v-if="item.IsPacking">({{ originalLebar(item.Lebar) }})</span>
      </template>
      <template v-slot:[`item.pTinggi`]="{ item }">
        {{ item.Tinggi }}
        <span v-if="item.IsPacking">({{ originalTinggi(item.Tinggi) }})</span>
      </template>
      <template v-slot:[`item.bPacking`]="{ item }">
        {{ convertToRp(item.BiayaPacking) }}
      </template>
    </v-data-table>

    <v-snackbar timeout="2000" v-model="snackbar" top color="cyan">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {
  // trackingJNE,
  urlTracking,
} from "../../../constant/url";
import {
  ADDITIONAL_LENGTH,
  ADDITIONAL_WIDTH,
  ADDITIONAL_HEIGHT,
} from "../../../constant/insurance";
import { formatToRupiah } from "../../../utils/formatter";

export default {
  name: "DropDownDetailOrder",
  props: ["order"],
  data() {
    return {
      headers: [
        { text: "Panjang", value: "pPanjang" },
        { text: "Lebar", value: "pLebar" },
        { text: "Tinggi", value: "pTinggi" },
        { text: "Berat Barang", value: "Berat" },
        { text: "Berat Volume", value: "BeratVolume" },
        { text: "Terberat / Total Berat", value: "Terberat" },
        { text: "Biaya Packing", value: "bPacking" },
        { text: "Packing", value: "packing" },
        // { text: "Action", value: "action", sortable: false },
      ],
      dialog: false,
      photo: null,
      signature: null,
      snackbar: false,
      snackbarText: "",
    };
  },
  computed: {
    detailColli() {
      let detail = [];
      for (let i = 0; i < this.order.length; i++) {
        for (let j = 0; j < this.order[i].JumlahKoli; j++) {
          detail.push({
            IDKoli: this.order[i].IDKoli,
            JumlahKoli: this.order[i].JumlahKoli,
            Berat: this.order[i].Berat,
            Panjang: this.order[i].Panjang,
            Lebar: this.order[i].Lebar,
            Tinggi: this.order[i].Tinggi,
            BeratVolume: this.order[i].BeratVolume,
            Terberat: this.order[i].Terberat,
            IsPacking: this.order[i].IsPacking,
            BiayaPacking: this.order[i].BiayaPacking,
            TotalBiaya: this.order[i].TotalBiaya,
            OrderIDOrder: this.order[i].OrderIDOrder,
            DescriptionItem: this.order[i].DescriptionItem,
          });
        }
      }
      return detail;
    },
  },
  methods: {
    sendOne(item) {
      console.log(item);
    },
    showPOD(awb) {
      this.photo = "Loading";
      this.signature = "Loading";
      this.dialog = true;
      axios({
        method: "GET",
        // url: `${trackingJNE}?carrier=JNE&waybill=${awb.wbNumber}`,
        url: `${urlTracking}${awb.wbNumber}`,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((res) => {
          if (res.data.data.cnote.photo) {
            this.photo = res.data.data.cnote.photo;
            this.signature = res.data.data.cnote.signature;
          } else {
            this.photo = null;
            this.signature = null;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.snackbar = true;
          this.dialog = false;
          this.snackbarText = "POD Tidak Di Temukan";
        });
    },
    originalPanjang(num) {
      return num - ADDITIONAL_LENGTH;
    },
    originalLebar(num) {
      return num - ADDITIONAL_WIDTH;
    },
    originalTinggi(num) {
      return num - ADDITIONAL_HEIGHT;
    },
    convertToRp(bil) {
      return formatToRupiah(bil);
    },
  },
};
</script>

<style lang="scss" scoped></style>
