<template>
  <div class="DialogEditHubComponent">
    <v-dialog v-model="dialogEditHub" persistent max-width="600px">
      <v-card>
        <v-card-title> Update Norek </v-card-title>

        <v-card-text>
          <v-form class="Form" ref="form" v-model="valid">
            <v-container>
              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="norek.NamaBank"
                    outlined
                    dense
                    label="Nama Bank"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="norek.NomorRekening"
                    outlined
                    dense
                    label="Nomor Rekening"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="pa-0">
                  <v-text-field
                    v-model="norek.MinTopup"
                    outlined
                    dense
                    type="number"
                    label="Minimal TopUP"
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" outlined @click="toggleDialog"> cancel </v-btn>

          <v-btn color="warning" depressed @click="updateHub"> update </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "DialogEditHubComponent",
  props: ["norek"],
  data() {
    return {
      dialogEditHub: false,
      valid: false,
      snackbar: false,
      snackbarText: "",
      requiredRule: [(value) => !!value || "Required"],
    };
  },
  methods: {
    toggleDialog() {
      this.dialogEditHub = !this.dialogEditHub;
    },
    updateHub() {
      this.valid = this.$refs.form.validate();

      if (this.valid) {
        axios({
          method: "put",
          url: `${baseUrl}/Norek/${this.norek.IDNorek}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            NamaBank: this.norek.NamaBank,
            NomorRekening: this.norek.NomorRekening,
            MinTopup: this.norek.MinTopup,
          },
        })
          .then((res) => {
            this.toggleDialog();
            this.snackbar = true;
            this.snackbarText = res.data.message.info[0];
            this.$emit("refresh");
          })
          .catch((err) => {
            this.snackbar = true;
            this.snackbarText = err.response.data.message.error[0];
          });
      }
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
