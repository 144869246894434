<template>
  <div class="NorekHO">
    <div class="NorekHO-Option">
      <v-container>
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-text-field
              outlined
              dense
              v-model="hub"
              label="Search"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <dialog-add-norek-component @refresh="fetchNorek" />
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="hub"
      :sort-by="sortBy"
      :loading="isLoading"
      disable-sort
    >
      <template v-slot:top>
        <dialog-edit-norek-component
          @refresh="fetchNorek"
          :norek="itemDetail"
          ref="editHubDialog"
        />

        <dialog-delete-norek-component
          @refresh="fetchNorek"
          :norek="itemDetail"
          ref="deleteHubDialog"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn depressed class="mr-2" small color="blue" @click="edit(item)">
          <v-icon color="white"> mdi-pencil </v-icon>
        </v-btn>

        <v-btn depressed small color="error" @click="deleteItem(item)">
          <v-icon color="white"> mdi-exclamation </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DialogAddNorekComponent from "../../../components/dashboard/settings/DialogAddNorekComponent.vue";
import DialogDeleteNorekComponent from "../../../components/dashboard/settings/DialogDeleteNorekComponent.vue";
import DialogEditNorekComponent from "../../../components/dashboard/settings/DialogEditNorekComponent.vue";

import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";

export default {
  name: "NorekHO",
  components: {
    DialogAddNorekComponent,
    DialogEditNorekComponent,
    DialogDeleteNorekComponent,
  },
  data() {
    return {
      hub: "",
      isLoading: false,
      itemDetail: {},

      sortBy: ["NamaBank"],
      headers: [
        { text: "Nama Bank", value: "NamaBank" },
        { text: "Nomor Rekening", value: "NomorRekening", filterable: false },
        { text: "Min. Topup", value: "MinTopup", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
      IDHO: getProfileCookie().KCX_HOIDHO,
    };
  },
  methods: {
    edit(item) {
      this.itemDetail = item;
      this.$refs.editHubDialog.toggleDialog();
    },
    deleteItem(item) {
      this.itemDetail = item;
      this.$refs.deleteHubDialog.toggleDialog();
    },
    fetchNorek() {
      this.isLoading = true;
      axios({
        method: "get",
        url: `${baseUrl}/Norek/${this.IDHO}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.isLoading = false;
          this.items = res.data.data;
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },
  },
  created() {
    this.fetchNorek();
    // this.fetchUserProfile();
  },
};
</script>

<style lang="scss" scoped>
.NorekHO {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
