<template>
  <div class="Home">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="pa-3" outlined tile>
          <div>
            <v-card class="pa-5" outlined tile>
              <v-card-title class="pa-1">
                <h3>Riwayat Transaksi</h3>
              </v-card-title>

              <v-row>
                <v-col cols="3">
                  <v-autocomplete
                    class="pt-3"
                    dense
                    label="Hub"
                    item-text="hubName"
                    item-value="idHub"
                    :items="hubList"
                    v-model="hub"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="9">
                  <div class="d-flex flex-row align-start">
                    <v-menu
                      ref="menu"
                      v-model="menuDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          label="From"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        no-title
                        scrollable
                        @input="menuDate = false"
                      >
                      </v-date-picker>
                    </v-menu>

                    <v-menu
                      ref="menu"
                      v-model="menuDateend"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          label="To"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        no-title
                        scrollable
                        @input="menuDateend = false"
                      >
                      </v-date-picker>
                    </v-menu>

                    <v-btn
                      depressed
                      color="cyan"
                      class="mt-4 ml-3 white--text"
                      @click="submit"
                      small
                    >
                      Search
                    </v-btn>
                    <v-btn
                      depressed
                      color="red"
                      class="mt-4 ml-3 white--text"
                      @click="reset"
                      small
                    >
                      Reset
                    </v-btn>
                    <v-btn
                      depressed
                      color="green"
                      class="mt-4 ml-3 white--text"
                      @click="exportToExcel"
                      small
                    >
                      Export
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
          <div class="mt-3">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-card>
                      <v-card-title>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-card-title>
                      <v-data-table
                        :headers="headers"
                        :items="transaction"
                        :search="search"
                        disable-sort
                      >
                        <template v-slot:top>
                          <dialog-order-transaction-component
                            ref="detailOrderDialog"
                          />
                        </template>
                        <template v-slot:item.OrderID="{ item }">
                          <div class="pa-1">
                            <span
                              v-if="item.TransactionType == 'TOPUP' && item.va"
                            >
                              Acoount Number : <br />
                              <strong>{{ item.va.account_number }}</strong>
                              <br />
                              Bank: <br />
                              {{ item.va.bank_code }}
                            </span>
                          </div>

                          <span v-if="item.topuphub">
                            <div class="pa-1">
                              Norek : <strong>{{ item.topuphub.Norek }}</strong>
                            </div>
                          </span>
                          <span v-else>
                            <div
                              class="pa-1"
                              v-for="(order, index) in item.order"
                              :key="index"
                            >
                              waybill :<a @click="showDetail(item.OrderID)"
                                ><strong>
                                  {{ order.InboundOrder.WBNumber }}</strong
                                ></a
                              >
                              <br />
                              berat : {{ order.InboundOrder.TotalBerat }}
                              <br />
                              biaya :
                              {{
                                formatToRupiah(order.InboundOrder.TotalBiaya)
                              }}
                            </div>
                          </span>
                        </template>
                        <template v-slot:item.TransactionType="{ item }">
                          <v-chip
                            v-if="item.TransactionType == 'TRANSFER'"
                            color="blue"
                            text-color="white"
                            x-small
                            >{{ item.TransactionType }}</v-chip
                          >
                          <v-chip
                            v-if="item.TransactionType == 'TOPUP'"
                            color="green"
                            text-color="white"
                            x-small
                            >{{ item.TransactionType }}</v-chip
                          >
                          <v-chip
                            v-if="item.TransactionType == 'KOMISI'"
                            color="orange"
                            text-color="white"
                            x-small
                            >{{ item.TransactionType }}</v-chip
                          >
                          <v-chip
                            v-if="item.TransactionType == 'CANCEL ORDER'"
                            color="red"
                            text-color="white"
                            x-small
                            >{{ item.TransactionType }}</v-chip
                          >
                        </template>
                        <template v-slot:item.Date="{ item }">
                          <span>{{ formatDate(item.Date) }}</span>
                        </template>
                        <template v-slot:item.HubName="{ item }">
                          <span v-if="item.TransactionType != 'TOPUP'">
                            Hub :
                            <span v-if="item.order[0]">{{
                              item.order[0].Hub.NamaHub
                            }}</span>
                            <span v-if="item.topuphub">{{
                              item.topuphub.Hub.NamaHub
                            }}</span>
                            <br />
                            <span v-if="item.order[0]">{{
                              item.order[0].OrderType
                            }}</span>
                            <span v-if="item.topuphub">{{
                              item.topuphub.NamaBank
                            }}</span></span
                          ><span v-else>Topup Saldo</span>
                        </template>
                        <template v-slot:item.Amount="{ item }">
                          <span v-if="item.IsDebit" class="green--text"
                            ><strong>{{
                              formatToRupiah(item.Amount)
                            }}</strong></span
                          >
                          <span v-else class="red--text"
                            ><strong>{{
                              formatToRupiah(item.Amount)
                            }}</strong></span
                          >
                        </template>
                        <template v-slot:item.AmountDebit="{ item }">
                          <span v-if="!item.IsDebit">{{
                            formatToRupiah(item.Amount)
                          }}</span>
                        </template>
                        <template v-slot:item.AmountCredit="{ item }">
                          <span v-if="item.IsDebit">{{
                            formatToRupiah(item.Amount)
                          }}</span>
                        </template>
                        <template v-slot:item.Balance="{ item }">
                          <span>{{ formatToRupiah(item.NewBalance) }}</span>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { formatToRupiah, externalFormatID } from "@/utils/formatter.js";
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";
import timezone from "moment-timezone";
import exportFromJSON from "export-from-json";
import DialogOrderTransactionComponent from "../../../components/dashboard/inbound/DialogOrderTransactionComponent.vue";

export default {
  name: "Dashboard",
  components: { DialogOrderTransactionComponent },
  data: () => ({
    isLoading: false,
    snackbar: false,
    snackbarText: "",
    popupTopup: false,
    menuDate: false,
    menuDateend: false,
    startDate: timezone()
      .tz("Asia/Jakarta")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: timezone().tz("Asia/Jakarta").endOf("month").format("YYYY-MM-DD"),
    search: "",
    searchIn: "",
    searchOut: "",
    balance: 0,
    headers: [
      { text: "Trx. ID", value: "TransactionID" },
      {
        text: "Date",
        align: "start",
        filterable: false,
        value: "Date",
      },
      { text: "Service", value: "HubName" },
      // { text: "Service", value: "Service" },
      { text: "Trx. Type", value: "TransactionType" },
      { text: "Detail Order", value: "OrderID" },
      { text: "Debit", value: "AmountDebit" },
      { text: "Credit", value: "AmountCredit" },
      { text: "Balance", value: "Balance" },
    ],
    listBank: [],
    listVA: [],
    transaction: [],
    transactionIn: [],
    transactionOut: [],
    IDHO: getProfileCookie().KCX_HOIDHO,
    numberVA: "",
    namaBank: "",
    externalID: externalFormatID(getProfileCookie()),
    expected_amount: 0,
    isLoadingTopup: false,
    hubList: [],
    hub: "",
  }),
  created() {
    this.fetchHub();
    // this.fetchBalance();
    this.fetchTransaction(this.startDate, this.endDate);
  },
  computed: {},
  watch: {},
  methods: {
    fetchHub() {
      this.isLoading = true;
      // console.log(getProfileCookie().KCX_HOIDHO);
      axios({
        method: "get",
        url: `${baseUrl}/Hub`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          idho: getProfileCookie().KCX_HOIDHO,
        },
      })
        .then((res) => {
          this.isLoading = false;
          const filteredItem = res.data.data.filter((el) => el.IDHub != 0);

          this.hubList = filteredItem.map((hub) => {
            // console.log(hub);
            return {
              idHub: hub.IDHub,
              hubName: hub.NamaHub,
              city: hub.City.NamaCity,
              idCity: hub.City.IDCity,
              phone: hub.Telp,
              email: hub.Email,
              supervisor: hub.Supervisor,
              description: hub.Keterangan,
              address: hub.Alamat,
              status: hub.Status,
              apiKey: hub.ApiKey,
            };
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },
    submit() {
      const startDt = timezone(this.startDate).tz("Asia/Jakarta");
      const endDt = timezone(this.endDate).tz("Asia/Jakarta");
      if (startDt > endDt) {
        this.snackbar = true;
        this.colorSnacbar = "red";
        this.snackbarText = "your end date must before the start date";
        return;
      }

      this.fetchTransaction(
        timezone(this.startDate).tz("Asia/Jakarta").format("YYYY-MM-DD"),
        timezone(this.endDate).tz("Asia/Jakarta").format("YYYY-MM-DD")
      );
    },
    reset() {
      (this.startDate = timezone()
        .tz("Asia/Jakarta")
        .startOf("month")
        .format("YYYY-MM-DD")),
        (this.endDate = timezone()
          .tz("Asia/Jakarta")
          .endOf("month")
          .format("YYYY-MM-DD")),
        this.fetchTransaction(this.startDate, this.endDate);
      this.hub = "";
    },
    setAmount() {
      this.dataVA.amount = this.amount;
    },
    async fetchBalance() {
      this.isLoading = true;
      const IDHO = await getProfileCookie().KCX_HOIDHO;
      const res = await axios({
        method: "get",
        url: `${baseUrl}/Balance/${IDHO}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      this.balance = formatToRupiah(res.data.data.balance ?? 0);
      this.isLoading = false;
    },
    async fetchTransaction(startDate, endDate) {
      this.isLoading = true;
      let query = ``;

      if (startDate) query += `?startDate=${startDate}`;
      if (startDate && endDate) query += `&endDate=${endDate}`;
      const IDHO = await getProfileCookie().KCX_HOIDHO;
      const res = await axios({
        method: "get",
        url: `${baseUrl}/Balance/Transaction/${IDHO}${query}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          hubid: this.hub,
        },
      });
      // console.log(res.data.data);
      this.transaction = res.data.data;
      this.transactionIn = res.data.data.filter((el) => el.IsDebit == true);
      this.transactionOut = res.data.data.filter((el) => el.IsDebit == false);

      this.isLoading = false;
    },

    formatToRupiah(amount) {
      return formatToRupiah(amount);
    },
    formatDate(date) {
      return timezone(date).tz("Asia/Jakarta").format("DD-MM-YYYY HH:mm:ss");
    },
    showDetail(idTrx) {
      // console.log(idTrx);
      // this.idTrx = idTrx;
      this.$refs.detailOrderDialog.toggleDialog();
      this.$refs.detailOrderDialog.fetchOrder(idTrx);
    },
    exportToExcel() {
      const currentData = this.transaction;
      const data = [];
      let number = 1;

      currentData.forEach((el) => {
        data.push({
          Number: number,
          Date: timezone(el.Date)
            .tz("Asia/Jakarta")
            .format("YYYY-MM-DD HH:mm:ss"),
          "Trx. ID": el.TransactionID,
          "Trx. Type": el.TransactionType,
          // "Order ID": el.OrderID,
          Debit: el.IsDebit ? "" : el.Amount,
          Credit: el.IsDebit ? el.Amount : "",
          Balance: el.NewBalance,
        });

        number++;
      });

      const fileName = `History Topup - Transaction HUB ${this.startDate} ~ ${this.endDate}`;
      const exportType = "xls";

      exportFromJSON({
        data,
        fileName,
        exportType,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
