<template>
  <div class="DialogConfirmOrderComponent">
    <v-dialog v-model="dialogConfirmOrder" persistent>
      <v-card>
        <v-card-title>
          <div class="d-flex flex-row justify-space-between dialog-card-title">
            <div>Laporan Keuangan {{ this.propDetail.date }}</div>
            <div>
              <download-excel
                :data="datas"
                :name="excelFileName"
                :fields="jsonFields"
              >
                <v-btn depressed color="primary">Download Data</v-btn>
              </download-excel>
            </div>
          </div>
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-4"
          ></v-text-field>
          <v-divider></v-divider>
          <v-data-table
            :headers="headers"
            :items="datas"
            :sort-by="sortBy"
            :loading="isLoading"
            :search="search"
            :sort-desc="[true]"
          >
            <template v-slot:[`item.hubName`]="{ item }">
              {{ item.idHub }} - {{ item.namaHub }}
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="toggleDialog"> close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import moment from "moment";
import timezone from "moment-timezone";
import { getFinancialReportDetail } from "../../../services/hub";
import { formatToRupiah } from "../../../utils/formatter";
export default {
  name: "DialogDetailFinancialReport",
  props: ["propDetail", "userProfile"],
  data() {
    return {
      dialogConfirmOrder: false,
      snackbar: false,
      snackbarText: "",
      isLoading: false,
      datas: [],
      search: "",
      sortBy: [
        "date",
        "idHo",
        "idHub",
        "service",
        "total_qty",
        "ongkir",
        "totalAmount",
      ],
      headers: [
        { text: "Tanggal Transaksi", value: "date", filterable: true },
        { text: "ID Order", value: "idTrx", filterable: true },
        // { text: "ID HO", value: "idHo" },
        { text: "HUB", value: "hubName" },
        { text: "WB ID", value: "wbID", filterable: true },
        { text: "Origin", value: "origin", filterable: true },
        { text: "Destination", value: "destination", filterable: true },
        { text: "Service", value: "service", filterable: true },
        { text: "Type", value: "dfod", filterable: true },
        { text: "Qty Collie", value: "totalCollie", filterable: false },
        { text: "Weight", value: "weight", filterable: false },
        { text: "Ongkir", value: "ongkir", filterable: false },
        { text: "Komisi Hub", value: "komisiHub", filterable: false },
        { text: "Komisi HO", value: "komisiHO", filterable: false },
        { text: "Biaya Packing", value: "biayaPacking", filterable: false },
        { text: "Value of the item", value: "valueItem", filterable: false },
        { text: "Premi", value: "premi", filterable: false },
        { text: "PPN", value: "ppn", filterable: false },
        { text: "Ongkos 3PL", value: "price3PL", filterable: false },
        { text: "Surcharge", value: "Surcharges", filterable: false },
        { text: "Additional Price", value: "AddCosts", filterable: false },
        { text: "Total", value: "totalAmount", filterable: false },
      ],
      jsonFields: {
        "Tanggal Transaksi": "date",
        "Trx ID": "idTrx",
        "WB ID": "wbID",
        // "ID HO": "idHo",
        "ID HUB": "idHub",
        Origin: "origin",
        Destination: "destination",
        Service: "service",
        "Qty Collie": "totalCollie",
        Weight: "weight",
        Ongkir: "ongkir",
        KomisiHub: "komisiHub",
        KomisiHO: "komisiHO",
        "BIaya Packing": "biayaPacking",
        "Value of the item": "valueItem",
        Premi: "premi",
        PPN: "ppn",
        "Ongkos 3PL": "price3PL",
        Surcharge: "Surcharges",
        "Additional Price": "AddCosts",
        Total: "totalAmount",
      },
    };
  },
  watch: {
    propDetail() {
      this.getDetail();
    },
  },
  computed: {
    excelFileName() {
      const date = timezone(this.propDetail.plain_date)
        .tz("Asia/Jakarta")
        .format("DDMMYYYY");
      return `Financial${this.userProfile.KCX_HubIDHub}-${date}`;
    },
  },
  methods: {
    toggleDialog() {
      this.dialogConfirmOrder = !this.dialogConfirmOrder;
    },
    async getDetail() {
      try {
        this.datas = [];
        this.isLoading = true;
        const date = timezone(this.propDetail.plain_date)
          .tz("Asia/Jakarta")
          .format("YYYY-MM-DD");
        const data = await getFinancialReportDetail(
          date,
          this.propDetail.idHub
        );

        this.datas = data.map((v) => {
          let addcost = this.sumPrice(v.AddCosts) ?? 0;
          let surcharge = this.sumPrice(v.Surcharges) ?? 0;
          return {
            wbID: v.InboundOrder.WBNumber,
            price3PL: formatToRupiah(v.InboundOrder.Price3PL),
            idTrx: v.IDOrder,
            idHo: v.Hub.KCX_HOIDHO,
            idHub: v.KCX_HubIDHub,
            origin: v.ShipperLoc.NamaCity,
            namaHub: v.Hub.NamaHub,
            destination: v.Recipient
              ? v.Recipient.City.NamaCity
              : v.RecipientInter.Country.CountryName,
            // service: v.Recipient ? "Darat" : "Express",
            service: v.Moda,
            AddCosts: formatToRupiah(addcost),
            Surcharges: formatToRupiah(surcharge),
            totalCollie: v.TotalKoli,
            weight: v.TotalBerat,
            biayaPacking: formatToRupiah(parseFloat(v.TotalBiayaPacking)),
            ppn: formatToRupiah(v.PPN),
            premi: formatToRupiah(v.Insurance),
            // totalAmount: formatToRupiah(v.TotalBiaya),
            totalAmount: formatToRupiah(
              v.TotalBiaya -
                (v.KomisiHub + v.KomisiHO + v.InboundOrder.Price3PL)
            ),
            ongkir: formatToRupiah(
              v.TotalBiaya -
                (v.PPN +
                  v.Insurance +
                  parseFloat(v.TotalBiayaPacking) +
                  v.InboundOrder.Price3PL +
                  addcost +
                  surcharge)
            ),
            komisiHub: formatToRupiah(v.KomisiHub),
            komisiHO: formatToRupiah(v.KomisiHO),
            valueItem: formatToRupiah(v.EstimasiNilai),
            date: timezone(v.DateCreated)
              .tz("Asia/Jakarta")
              .format("DD/MM/YYYY"),
            dfod: v.Dfod ? "DFOD" : "CASH",
          };
        });
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    sumPrice(arr) {
      return arr.reduce((a, b) => a + (b["Price"] || 0), 0);
    },
  },
};
</script>

<style scoped>
.dialog-card-title {
  width: 100% !important;
}
</style>
