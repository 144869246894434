<template>
  <div class="Wallet">
    <v-row>
      <v-col cols="12" sm="12" class="pa-5">
        <h2>Saldo Trolly</h2>
        <p>Saldo tersedia saat ini</p>
      </v-col>
      <v-col cols="3" sm="3">
        <v-card class="pa-5" outlined tile>
          <v-row>
            <v-col cols="3" class="text-center">
              <v-icon large color="orange  darken-2">mdi-wallet</v-icon>
            </v-col>
            <v-col cols="9">
              <div class="pb-2">
                Saldo Aktif : <br />
                <v-progress-circular
                  indeterminate
                  color="primary"
                  v-if="isLoading"
                ></v-progress-circular>
                <strong v-else> {{ balance }}</strong>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="9" sm="9">
        <v-card class="pa-3" outlined tile>
          <v-row>
            <v-col cols="6">
              <div><h3>Isi Saldo</h3></div>
              <div>
                <small>Masukkan nominal yang anda inginkan</small>
              </div>
              <!-- <div class="mt-3 mb-3">
                <v-btn-toggle
                  v-model="dataVA.amount"
                  tile
                  color="deep-orange accent-3"
                  group
                  class="d-flex flex-wrap"
                  @input="setAmount"
                >
                  <v-btn small value="500000"> Rp. 500.000 </v-btn>
                  <v-btn small value="1000000"> Rp. 1.000.000 </v-btn>
                  <v-btn small value="2000000"> Rp. 2.000.000 </v-btn>
                </v-btn-toggle>
              </div> -->
              <div class="ma-3">
                <v-text-field
                  label="Amount"
                  v-model="dataVA.amount"
                  hide-spin-buttons
                  v-money="money"
                ></v-text-field>
              </div>
            </v-col>
            <v-col cols="6">
              <v-btn-toggle v-model="payment" mandatory @change="metodePayment">
                <v-btn small value="transfer"> Transfer Bank </v-btn>
                <!-- <v-btn small value="va"> Virtual Account </v-btn> -->
              </v-btn-toggle>
              <div class="mt-3 mb-3 pl-2">
                <v-btn-toggle
                  v-if="payment == 'transfer'"
                  v-model="dataVA.bankCode"
                  tile
                  color="blue accent-3"
                  group
                  class="d-flex flex-wrap"
                >
                  <v-btn
                    class="pa-4"
                    small
                    v-for="norek in norekHQ"
                    :key="norek.IDNorek"
                    :value="norek.NamaBank"
                    @click="setNorek(norek)"
                  >
                    {{ norek.NomorRekening }}
                    <v-chip small color="orange">{{
                      norek.NamaBank
                    }}</v-chip></v-btn
                  >
                </v-btn-toggle>
                <v-btn-toggle
                  v-model="dataVA.bankCode"
                  v-if="payment == 'va'"
                  tile
                  color="deep-orange accent-3"
                  group
                  class="d-flex flex-wrap"
                >
                  <v-btn
                    small
                    v-for="bank in listBank"
                    :key="bank.name"
                    :value="bank.code"
                    depressed
                  >
                    {{ bank.name }}
                  </v-btn>
                </v-btn-toggle>
              </div>
              <v-btn
                @click="topUp"
                class="mb-2"
                small
                block
                color="warning"
                dark
                >Isi Saldo
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" sm="12">
        <v-row>
          <v-col cols="12">
            <v-btn
              depressed
              :color="loadData == 'transaction' ? 'primary' : ''"
              @click="loadData = 'transaction'"
            >
              Riwayat Transaksi
            </v-btn>
            &nbsp;

            <v-btn
              depressed
              :color="loadData == 'topup' ? 'primary' : ''"
              @click="loadData = 'topup'"
            >
              Topup Saldo
            </v-btn>
            &nbsp;

            <v-btn
              depressed
              :color="loadData == 'topuphub' ? 'primary' : ''"
              @click="loadData = 'topuphub'"
            >
              TOPUP HUB
            </v-btn>
          </v-col>
        </v-row>
        <transaction v-if="loadData == 'transaction'" />
        <topup :loadBalance="fetchBalance" v-if="loadData == 'topuphub'" />
        <v-card class="pa-3 mt-3" outlined tile v-if="loadData == 'topup'">
          <div>
            <v-card-title class="pa-5">
              <v-row>
                <v-col cols="12">
                  <h3 class="mb-5">Riwayat Topup Saldo</h3>
                  <div class="d-flex flex-row align-start">
                    <v-menu
                      ref="menu"
                      v-model="menuDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          label="From"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        no-title
                        scrollable
                        @input="menuDate = false"
                      >
                      </v-date-picker>
                    </v-menu>

                    <v-menu
                      ref="menu"
                      v-model="menuDateend"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          label="To"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        no-title
                        scrollable
                        @input="menuDateend = false"
                      >
                      </v-date-picker>
                    </v-menu>

                    <v-btn
                      depressed
                      color="cyan"
                      class="mt-4 ml-3 white--text"
                      @click="submit"
                      small
                    >
                      Search
                    </v-btn>
                    <v-btn
                      depressed
                      color="red"
                      class="mt-4 ml-3 white--text"
                      @click="reset"
                      small
                    >
                      Reset
                    </v-btn>
                    <v-btn
                      depressed
                      color="green"
                      class="mt-4 ml-3 white--text"
                      @click="exportToExcel"
                      small
                    >
                      Export
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card-title>
          </div>
          <div>
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-card>
                      <v-card-title>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-card-title>
                      <v-data-table
                        :headers="headers"
                        :items="listVA"
                        :search="search"
                        disable-sort
                      >
                        <template v-slot:item.created_at="{ item }">
                          <span>{{ formatDate(item.created_at) }}</span>
                        </template>
                        <template v-slot:item.account_number="{ item }">
                          <span v-if="item.PaymentType == 'TRANSFER'"
                            >{{ item.account_number }} / Transfer</span
                          >
                          <span v-else>{{ item.account_number }} / VA</span>
                        </template>
                        <template v-slot:item.expected_amount="{ item }">
                          <strong>{{
                            formatToRupiah(item.expected_amount)
                          }}</strong>
                        </template>

                        <template v-slot:[`item.status`]="{ item }">
                          <div v-if="item.PaymentType == 'TRANSFER'">
                            <span v-if="item.BuktiTF">
                              <span v-if="item.status == 'PAID'">
                                <v-chip
                                  class="ma-2 white--text"
                                  color="green"
                                  small
                                >
                                  PAID</v-chip
                                ></span
                              >
                              <span v-if="item.status == 'REJECT'">
                                <v-chip
                                  class="ma-2 white--text"
                                  color="red"
                                  small
                                >
                                  REJECT</v-chip
                                ></span
                              >

                              <span v-if="item.status == 'REVIEW'">
                                <v-chip
                                  class="ma-2 white--text"
                                  color="info"
                                  small
                                >
                                  REVIEW</v-chip
                                >
                              </span>
                            </span>
                            <span v-else>
                              <v-chip
                                class="ma-2 white--text"
                                color="secondary"
                                small
                              >
                                belum upload
                              </v-chip>
                            </span>
                          </div>
                          <div v-else>
                            <v-chip
                              class="ma-2 white--text"
                              :color="
                                item.status == 'PAID' ? 'green' : 'orange'
                              "
                              small
                            >
                              {{ item.status }}
                            </v-chip>
                          </div>
                          <button></button>
                        </template>
                        <template v-slot:[`item.uploadTF`]="{ item }">
                          <div v-if="item.PaymentType == 'TRANSFER'">
                            <div v-if="item.BuktiTF">
                              <a :href="item.BuktiTF" target="_blank"
                                >Lihat Bukti TF</a
                              >
                            </div>
                            <dialog-upload-TF-component
                              v-else
                              :loadRiwayatTopup="listVAHO"
                              :loadBalance="fetchBalance"
                              :itemData="item"
                              :startDate="startDate"
                              :endDate="endDate"
                            />
                          </div>
                          <div v-else>Virtual Account</div>
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="popupTopup"
      persistent
      transition="dialog-bottom-transition"
      max-width="600"
    >
      <v-card>
        <v-toolbar color="warning" dark>Number VA</v-toolbar>
        <v-card-text v-if="!isLoadingTopup">
          <div class="pa-5" v-if="payment == 'va'">
            <h3>No. VA</h3>
            <p class="text-h5">{{ numberVA }}</p>
            <br />
            <h3>Amount</h3>
            <p class="text-h5">{{ formatToRupiah(expected_amount) }}</p>
            <h3>Bank</h3>
            <p class="text-h5">{{ namaBank }}</p>
          </div>
          <div class="pa-5" v-else>
            <h3>No. Rekening</h3>
            <p class="text-h5">{{ numberVA }}</p>
            <br />
            <h3>Amount</h3>
            <p class="text-h5">{{ formatToRupiah(expected_amount) }}</p>
            <h3>Bank</h3>
            <p class="text-h5">{{ namaBank }}</p>
          </div>
        </v-card-text>
        <v-progress-circular
          class="ma-5"
          v-else
          indeterminate
          color="primary"
        ></v-progress-circular>
        <v-card-actions class="justify-end">
          <v-btn text @click="popupTopup = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar timeout="2000" v-model="snackbar" top :color="colorSnackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { formatToRupiah, externalFormatID } from "@/utils/formatter.js";
import axios from "axios";
import { baseUrl, urlVA } from "../../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";
import https from "https";
import http from "http";
import timezone from "moment-timezone";
import exportFromJSON from "export-from-json";
import DialogUploadTFComponent from "./../../../components/dashboard/inbound/DialogUploadTFComponent.vue";
import Transaction from "./Transaction.vue";
import Topup from "./Topup.vue";

export default {
  name: "Wallet",
  components: { DialogUploadTFComponent, Transaction, Topup },
  data: () => ({
    colorSnackbar: "orange",
    isLoading: false,
    snackbar: false,
    snackbarText: "",
    popupTopup: false,
    search: "",
    searchIn: "",
    searchOut: "",
    balance: 0,
    headers: [
      {
        text: "Date",
        align: "start",
        filterable: false,
        value: "created_at",
      },
      { text: "Nama Bank", value: "bank_code" },
      { text: "Norek", value: "account_number" },
      { text: "Amount", value: "expected_amount" },
      { text: "Bukti TF", value: "uploadTF", filterable: false },
      { text: "Status", value: "status" },
    ],
    money: {
      decimal: ",",
      thousands: ".",
      precision: 0,
      masked: false,
    },
    listBank: [],
    listVA: [],
    transaction: [],
    transactionIn: [],
    transactionOut: [],
    dataVA: {
      bankCode: "",
      amount: 500000,
      name: getProfileCookie().NamaDepan,
      description: "TOPUP HO",
      norek: "",
    },
    amount: 500000,
    IDHO: getProfileCookie().KCX_HOIDHO,
    numberVA: "",
    namaBank: "",
    externalID: externalFormatID(getProfileCookie()),
    expected_amount: 0,
    isLoadingTopup: false,
    menuDate: false,
    menuDateend: false,
    startDate: timezone()
      .tz("Asia/Jakarta")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: timezone().tz("Asia/Jakarta").endOf("month").format("YYYY-MM-DD"),
    payment: null,
    norekHQ: [],
    loadData: "transaction",
  }),
  created() {
    this.fetchBalance();
    this.listVAHO(this.startDate, this.endDate);
  },
  computed: {},
  watch: {},
  methods: {
    setNorek(data) {
      // console.log(data);
      this.dataVA.norek = data.NomorRekening;
    },
    submit() {
      const startDt = timezone(this.startDate).tz("Asia/Jakarta");
      const endDt = timezone(this.endDate).tz("Asia/Jakarta");
      if (startDt > endDt) {
        this.snackbar = true;
        this.colorSnacbar = "red";
        this.snackbarText = "your end date must before the start date";
        return;
      }

      this.listVAHO(
        timezone(this.startDate).tz("Asia/Jakarta").format("YYYY-MM-DD"),
        timezone(this.endDate).tz("Asia/Jakarta").format("YYYY-MM-DD")
      );
    },
    reset() {
      (this.startDate = timezone()
        .tz("Asia/Jakarta")
        .startOf("month")
        .format("YYYY-MM-DD")),
        (this.endDate = timezone()
          .tz("Asia/Jakarta")
          .endOf("month")
          .format("YYYY-MM-DD")),
        this.listVAHO(this.startDate, this.endDate);
    },
    exportToExcel() {
      const currentData = this.transaction;
      const data = [];
      let number = 1;

      currentData.forEach((el) => {
        data.push({
          Number: number,
          "Trx. ID": el.TransactionID,
          Date: el.Date,
          "Trx. Type": el.TransactionType,
          "Order ID": el.OrderID,
          Debit: el.IsDebit ? "" : el.Amount,
          Credit: el.IsDebit ? el.Amount : "",
          Balance: el.NewBalance,
        });

        number++;
      });

      const fileName = "RecapTransaction";
      const exportType = "xls";

      exportFromJSON({
        data,
        fileName,
        exportType,
      });
    },
    setAmount() {
      console.log(this.amount);
      this.dataVA.amount = this.amount;
    },
    async fetchBalance() {
      this.isLoading = true;
      const IDHO = await getProfileCookie().KCX_HOIDHO;
      const res = await axios({
        method: "get",
        url: `${baseUrl}/Balance/${IDHO}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      this.balance = formatToRupiah(res.data.data.balance ?? 0);
      this.isLoading = false;
    },
    async listVAHO(startDate, endDate) {
      let query = ``;

      if (startDate) query += `?startDate=${startDate}`;
      if (startDate && endDate) query += `&endDate=${endDate}`;
      const res = await axios({
        method: "get",
        url: `${baseUrl}/deposit/topupHO/${this.IDHO}${query}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      // console.log(res);
      this.listVA = res.data.data;
    },
    async metodePayment() {
      if (this.payment == "va") {
        await this.getVABanks();
        await this.getNorek();
      } else {
        if (this.norekHQ.length == 0) await this.getNorek();
      }
    },
    async getNorek() {
      let norek = await axios({
        method: "get",
        url: `${baseUrl}/Norek/0`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      this.norekHQ = norek.data.data;
    },
    async getVABanks() {
      const res = await axios({
        method: "get",
        url: `${urlVA}/listBankVA`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      this.listBank = res.data.result.data;
    },
    async topUp() {
      if (this.dataVA.bankCode.length == 0) {
        this.snackbar = true;
        this.snackbarText = "Silahkan Pilih Bank Terlebih Dahulu";
        return;
      }
      if (this.dataVA.amount.replaceAll(".", "") < 10000) {
        this.snackbar = true;
        this.snackbarText = "Minimum Topup adalah Rp. 10.000";
        return;
      }

      try {
        this.isLoadingTopup = true;
        this.popupTopup = true;
        let data, res;
        //VA PAYMENT
        if (this.payment == "va") {
          data = {
            bankCode: this.dataVA.bankCode,
            amount: Number(this.dataVA.amount.replaceAll(".", "")),
            name: this.dataVA.name,
            description: this.dataVA.description,
            externalID: this.externalID,
          };
          res = await axios({
            method: "post",
            url: `${urlVA}/createCloseVA`,
            data: data,
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
              idho: this.IDHO,
            },
            httpsAgent: new https.Agent({ keepAlive: true }),
            httpAgent: new http.Agent({ keepAlive: true }),
          });
          this.numberVA = res.data.result.data.account_number;
          this.expected_amount = res.data.result.data.expected_amount;
        } else {
          data = {
            bankCode: this.dataVA.bankCode,
            amount: Number(this.dataVA.amount.replaceAll(".", "")),
            name: this.dataVA.name,
            description: this.dataVA.description,
            externalID: this.externalID,
            norek: this.dataVA.norek,
          };
          // console.log(data);
          res = await axios({
            method: "post",
            url: `${baseUrl}/deposit/topupHO`,
            data: data,
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
              idho: this.IDHO,
            },
            httpsAgent: new https.Agent({ keepAlive: true }),
            httpAgent: new http.Agent({ keepAlive: true }),
          });
          this.numberVA = this.dataVA.norek;
          this.expected_amount = res.data.data.expected_amount;
        }

        this.namaBank = this.dataVA.bankCode;
      } catch (error) {
        this.popupTopup = false;
        this.snackbar = true;
        this.snackbarText = "Terjadi Kesalahan. Silahkan Ulangi Lagi!";
      } finally {
        this.isLoadingTopup = false;
        this.listVAHO(this.startDate, this.endDate);
      }
    },
    formatToRupiah(amount) {
      return formatToRupiah(amount);
    },
    formatDate(date) {
      return timezone(date).tz("Asia/Jakarta").format("DD-MM-YYYY HH:mm:ss");
    },
    showDetail(idTrx) {
      // console.log(idTrx);
      // this.idTrx = idTrx;
      this.$refs.detailOrderDialog.toggleDialog();
      this.$refs.detailOrderDialog.fetchOrder(idTrx);
    },
  },
};
</script>

<style lang="scss" scoped></style>
