<template>
  <div class="DetailTransaction">
    <v-container class="ma-0 mb-4">
      <v-row>
        <v-col sm="2" class="pa-0">
          <v-text-field
            class="mr-2"
            v-model="startDate"
            type="date"
            outlined
            label="From"
            dense
          ></v-text-field>
        </v-col>

        <v-col sm="2" class="pa-0">
          <v-text-field
            v-model="endDate"
            type="date"
            outlined
            label="To"
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="2" class="pa-0">
          <v-select
            class="mr-2"
            outlined
            dense
            label="Origin"
            item-text="text"
            item-value="value"
            :items="originList"
            v-model="origin"
          ></v-select>
        </v-col>

        <v-col sm="2" class="pa-0">
          <v-select
            outlined
            dense
            label="Destination"
            item-text="text"
            item-value="value"
            :items="destinationList"
            v-model="destination"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="4" class="pa-0">
          <v-select
            outlined
            dense
            label="Status"
            item-text="text"
            item-value="value"
            :items="statusList"
            v-model="status"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="4" class="pa-0 mr-2">
          <v-btn depressed color="warning" class="mr-2" @click="search"
            >Search</v-btn
          >

          <v-btn depressed color="error" @click="refresh">refresh</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :loading="isLoading"
      :sort-by="sortBy"
      :sort-desc="[true]"
      :expanded.sync="expanded"
      :single-expand="true"
      show-expand
      disable-sort
      item-key="idOrder"
    >
      <template v-slot:top>
        <dialog-view-transaction-component
          :transaction="itemDetail"
          ref="viewTransactionDialog"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn depressed class="mr-2" small color="success" @click="view(item)">
          <v-icon color="white"> mdi-eye </v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="pa-0">
          <drop-down-detail-order :order="item.detailColly" />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DialogViewTransactionComponent from "../../../components/dashboard/module/DialogViewTransactionComponent.vue";
import DropDownDetailOrder from "../../../components/dashboard/inbound/DropDownDetailOrder.vue";
import { formatToRupiah } from "@/utils/formatter.js";
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";
import moment from "moment";

export default {
  components: { DialogViewTransactionComponent, DropDownDetailOrder },
  name: "DetailTransaction",
  data() {
    return {
      itemDetail: {},
      startDate: "",
      endDate: "",
      status: "",
      origin: "",
      destination: "",
      originList: [],
      destinationList: [],
      statusList: [],
      isLoading: false,
      sortBy: [
        "date",
        "shipper",
        "origin",
        "recipient",
        "destination",
        "colly",
        "weight",
        "price",
        "hub",
        "status",
      ],
      headers: [
        { text: "ID Order", value: "idOrder", filterable: false },
        { text: "Wb Number", value: "wbNumber", filterable: false },
        { text: "Date", value: "date" },
        { text: "Hub", value: "hub", filterable: false },
        { text: "Shipper", value: "shipper", filterable: false },
        { text: "Origin", value: "origin", filterable: false },
        { text: "Recipient", value: "recipient", filterable: false },
        { text: "Destination", value: "destination", filterable: false },
        { text: "Type", value: "orderType", filterable: false },
        { text: "Koli", value: "colly", filterable: false },
        { text: "Berat", value: "weight", filterable: false },
        { text: "Harga", value: "price", filterable: false },
        { text: "Status", value: "status", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
      filteredItems: [],
      expanded: [],
    };
  },
  methods: {
    view(item) {
      this.itemDetail = item;
      this.$refs.viewTransactionDialog.toggleDialog();
    },
    async fetchOrders() {
      this.isLoading = true;

      axios({
        method: "get",
        url: `${baseUrl}/InboundOrders`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          idho: await getProfileCookie().KCX_HOIDHO,
        },
      })
        .then((res) => {
          const items = res.data.data.map((order) => {
            if (order.Order) {
              let dest, namaShipper;
              if (order.Order.Recipient) {
                dest = order.Order.Recipient.City?.NamaCity ?? "";
                namaShipper = order.Order.Recipient?.Nama ?? "";
              } else if (order.Order.RecipientInter) {
                dest = order.Order.RecipientInter.Country?.CountryName ?? "";
                namaShipper = order.Order.RecipientInter?.Nama ?? "";
              }

              return {
                idOrder: order.Order.IDOrder,
                orderType: order.Order.OrderType,
                wbNumber: order.WBNumber,
                date: moment(order.DateCreated)
                  // .add(7, "hours")
                  .format("YYYY-MM-DD, HH:mm"),
                origin: order.Order.ShipperLoc.NamaCity,
                shipper: order.Order.ShipperLoc.Nama,
                recipient: namaShipper,
                destination: dest,
                colly: order.TotalKoli,
                weight: order.TotalBerat,
                status: order.PackageStatus.Status,
                price: formatToRupiah(order.TotalBiaya),
                hub: order.Order.Hub.NamaHub,
                detailColly: order.Order.Collies,
              };
            } else {
              return {};
            }
          });
          this.filteredItems = items;
          this.items = items;
          this.generateList(items);
          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
          this.items = [];
          this.isLoading = false;
        });
    },
    generateList(data) {
      this.statusList = [
        ...new Set(
          data.map((item) => {
            if (item) {
              return item.status;
            }
          })
        ),
      ];
      this.originList = [
        ...new Set(
          data.map((item) => {
            if (item) {
              return item.origin;
            }
          })
        ),
      ];
      this.destinationList = [
        ...new Set(
          data.map((item) => {
            if (item) {
              return item.destination;
            }
          })
        ),
      ];
    },
    search() {
      this.filteredItems = this.items;

      if (this.startDate && this.endDate) {
        this.filterDate();
      }

      if (this.origin) {
        this.filterOrigin();
      }

      if (this.destination) {
        this.filterDestination();
      }

      if (this.status) {
        this.filterStatus();
      }
    },
    refresh() {
      this.filteredItems = this.items;
      this.startDate = "";
      this.endDate = "";
      this.destination = "";
      this.origin = "";
      this.status = "";
      this.fetchOrders();
    },
    filterOrigin() {
      this.filteredItems = this.filteredItems.filter((el) => {
        if (el) {
          return el.origin == this.origin;
        }
      });
    },
    filterDestination() {
      this.filteredItems = this.filteredItems.filter((el) => {
        if (el) {
          return el.destination == this.destination;
        }
      });
    },
    filterStatus() {
      this.filteredItems = this.filteredItems.filter((el) => {
        if (el) {
          return el.status == this.status;
        }
      });
    },
    filterDate() {
      this.filteredItems = this.filteredItems.filter((el) => {
        if (el) {
          return el.date >= this.startDate && el.date <= this.endDate;
        }
      });
    },
  },
  created() {
    this.fetchOrders();
  },
};
</script>

<style lang="scss" scoped>
.DetailTransaction {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
}
</style>
