<template>
  <div class="Wallet">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="pa-3">
          <v-card-title class="pa-5">
            <h3 class="mb-5">Riwayat Topup Hub</h3>

            <v-row>
              <!-- <v-col cols="3">
                <div class="pb-2">
                  Saldo Aktif : <br />
                  <v-progress-circular
                    indeterminate
                    color="primary"
                    v-if="isLoading"
                  ></v-progress-circular>
                  <strong v-else> {{ formatToRupiah(balance ?? 0) }}</strong>
                </div>
              </v-col> -->
              <v-col cols="4">
                <v-select
                  label="Hub"
                  item-text="text"
                  item-value="value"
                  :items="hubList"
                  v-model="hub"
                ></v-select>
              </v-col>
              <v-col cols="8">
                <div class="d-flex flex-row align-start">
                  <v-menu
                    ref="menu"
                    v-model="menuDate"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="startDate"
                        label="From"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      no-title
                      scrollable
                      @input="menuDate = false"
                    >
                    </v-date-picker>
                  </v-menu>

                  <v-menu
                    ref="menu"
                    v-model="menuDateend"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="endDate"
                        label="To"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="endDate"
                      no-title
                      scrollable
                      @input="menuDateend = false"
                    >
                    </v-date-picker>
                  </v-menu>

                  <v-btn
                    depressed
                    color="cyan"
                    class="mt-4 white--text"
                    @click="submit"
                    small
                  >
                    Search
                  </v-btn>

                  <v-btn
                    depressed
                    color="red"
                    class="mt-4 white--text ml-1"
                    @click="refresh"
                    small
                  >
                    Refresh
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="headersTopup"
              :items="filteredItems"
              :sort-by="sortByTopup"
              :sort-desc="[true]"
              :loading="isLoading"
              disable-sort
              ><template v-slot:[`item.AmountFmt`]="{ item }">
                {{ formatToRupiah(item.Amount + item.NoBuffer) }}
              </template>
              <template v-slot:[`item.createdAtFmt`]="{ item }">
                {{ formatToDate(item.createdAt) }}
              </template>
              <template v-slot:[`item.uploadTF`]="{ item }">
                <dialog-approve-topup-component
                  :loadRiwayatTopup="getRiwayatTopup"
                  :loadBalanceParent="loadBalance"
                  :loadBalance="fetchBalance"
                  :itemData="item"
                  :startDate="startDate"
                  :endDate="endDate"
                  :balance="balance"
                />
              </template>
              <template v-slot:[`item.valid`]="{ item }">
                <span v-if="item.BuktiTF">
                  <span v-if="item.IsValid == true">
                    <v-chip class="ma-2 white--text" color="green" small>
                      Success</v-chip
                    ></span
                  >
                  <span v-else-if="item.IsValid == false">
                    <v-chip class="ma-2 white--text" color="red" small>
                      Reject</v-chip
                    ></span
                  >
                  <span v-else>
                    <v-chip class="ma-2 white--text" color="info" small>
                      Review</v-chip
                    >
                  </span>
                </span>
                <span v-else>
                  <v-chip class="ma-2 white--text" color="secondary" small>
                    belum upload
                  </v-chip>
                </span>
                <button></button>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar timeout="2000" v-model="snackbar" top :color="colorSnacbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { formatToRupiah, externalFormatID } from "@/utils/formatter.js";
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";
import timezone from "moment-timezone";
import DialogApproveTopupComponent from "../../../components/dashboard/inbound/DialogApproveTopupComponent.vue";

export default {
  name: "Wallet",
  components: { DialogApproveTopupComponent },
  props: ["loadBalance"],
  data: () => ({
    isLoading: false,
    snackbar: false,
    snackbarText: "",
    popupTopup: false,
    search: "",
    searchIn: "",
    searchOut: "",
    balance: 0,
    colorSnacbar: "green",
    loadingSaldo: false,
    showSaldo: false,
    menuDate: false,
    menuDateend: false,
    valid: false,
    listBank: [],
    listVA: [],
    transaction: [],
    transactionIn: [],
    transactionOut: [],
    dataVA: {
      bankCode: "",
      amount: 500000,
      name: getProfileCookie().NamaDepan,
      description: "TOPUP HO",
    },
    amount: 500000,
    IDHO: getProfileCookie().KCX_HOIDHO,
    numberVA: "",
    externalID: externalFormatID(getProfileCookie()),
    startDate: timezone()
      .tz("Asia/Jakarta")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: timezone().tz("Asia/Jakarta").endOf("month").format("YYYY-MM-DD"),
    message: "",
    sortBy: [
      // "packingId",
      "date",
      "destination",
      "totalColly",
      "totalWeight",
      // "status",
    ],
    sortByTopup: [
      // "packingId",
      "date",
      "destination",
      "totalColly",
      "totalWeight",
      // "status",
    ],
    city: "",
    toDate: "",
    fromDate: "",
    headers: [
      {
        text: "Tanggal",
        value: "packingId",
        filterable: false,
      },
      { text: "No. Referensi", value: "date", filterable: false },
      { text: "Jenis Transaksi", value: "destination" },
      { text: "Nominal", value: "totalColly", filterable: false },
      { text: "Status", value: "totalColly", filterable: false },
    ],
    headersTopup: [
      {
        text: "Tanggal",
        value: "createdAtFmt",
        filterable: false,
      },
      { text: "Nama Hub", value: "Hub.NamaHub", filterable: false },
      { text: "Bank", value: "NamaBank", filterable: false },
      { text: "Norek", value: "Norek", filterable: false },
      {
        text: "Nominal",
        value: "AmountFmt",
        filterable: false,
      },
      { text: "Bukti TF", value: "uploadTF", filterable: false },

      { text: "Status", value: "valid", filterable: false },
    ],
    items: [],
    itemsTopup: [],
    selected: [],
    isShowCheckbox: false,
    norekHO: [],
    HubID: getProfileCookie().KCX_HubIDHub,
    dataRiwayat: "topup",
    hubList: [],
    hub: "",
    filteredItems: [],
  }),
  created() {
    this.fetchBalance();
    this.getRiwayatTopup(this.startDate, this.endDate);
  },
  computed: {},
  watch: {},
  methods: {
    generateList(data) {
      this.hubList = [...new Set(data.map((item) => item.Hub.NamaHub))];
    },
    filterHub() {
      this.filteredItems = this.filteredItems.filter((el) => {
        return el.hub == this.hub;
      });
    },
    formatToRupiah(amount) {
      return formatToRupiah(amount);
    },
    formatDate(date) {
      return timezone(date).tz("Asia/Jakarta").format("L");
    },
    loadData(mode) {
      this.dataRiwayat = mode;
    },
    async fetchBalance() {
      this.isLoading = true;
      const IDHO = await getProfileCookie().KCX_HOIDHO;
      const res = await axios({
        method: "get",
        url: `${baseUrl}/Balance/${IDHO}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      this.balance = res.data.data.balance;
      this.isLoading = false;
    },

    formatToDate(date) {
      // return date;
      return timezone(date).tz("Asia/Jakarta").format("DD-MM-YYYY HH:mm");
    },
    view() {
      this.showSaldo = !this.showSaldo;
    },
    submit() {
      this.filteredItems = this.itemsTopup;

      const startDt = timezone(this.startDate).tz("Asia/Jakarta");
      const endDt = timezone(this.endDate).tz("Asia/Jakarta");
      if (startDt > endDt) {
        this.snackbar = true;
        this.colorSnacbar = "red";
        this.snackbarText = "your end date must before the start date";
        return;
      }
      this.getRiwayatTopup(
        timezone(this.startDate).tz("Asia/Jakarta").format("YYYY-MM-DD"),
        timezone(this.endDate).tz("Asia/Jakarta").format("YYYY-MM-DD")
      );
      if (this.hub) {
        this.filterHub();
      }
    },
    refresh() {
      (this.startDate = timezone()
        .tz("Asia/Jakarta")
        .startOf("month")
        .format("YYYY-MM-DD")),
        (this.endDate = timezone()
          .tz("Asia/Jakarta")
          .endOf("month")
          .format("YYYY-MM-DD")),
        this.getRiwayatTopup(this.startDate, this.endDate);
    },

    async getRiwayatTopup(startDate, endDate) {
      let query = ``;

      if (startDate) query += `?startDate=${startDate}`;
      if (startDate && endDate) query += `&endDate=${endDate}`;
      let riwayatTopup = await axios({
        method: "get",
        url: `${baseUrl}/Balance/TopupHubHO/${this.IDHO}${query}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      this.filteredItems = riwayatTopup.data.data;
      this.generateList(this.filteredItems);
    },
  },
};
</script>

<style lang="scss" scoped></style>
